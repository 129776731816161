<template>
  <form role="form" @submit="onFormSubmit" name="unavailable-period">
    <div class="unavailable-period-form">
      <div class="row" v-if="availability.title">
        <div class="col-lg-12">
          <h2>{{getLocalizedText(availability.title)}}</h2>
        </div>
      </div>
      
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('shop.availabilities.unavailable-periods.info.information')}}</h3>
          <p>{{$t('shop.availabilities.unavailable-periods.create.note')}}</p>
        </div>
      </div>
      <DateInput 
        v-bind:value.sync="unavailablePeriodForm.startDate" 
        type="datetime-local"
        :label="$t('shop.availabilities.unavailable-periods.info.startDate')" 
        required 
        labelAsColumn 
      />
      <DateInput 
        v-bind:value.sync="unavailablePeriodForm.endDate" 
        type="datetime-local"
        :label="$t('shop.availabilities.unavailable-periods.info.endDate')" 
        required 
        labelAsColumn 
      />
      <TextField 
        v-bind:value.sync="unavailablePeriodForm.reason" 
        id="unavailablePeriodReason" 
        :label="$t('shop.availabilities.unavailable-periods.info.reason')" 
        :placeholder="$t('shop.availabilities.unavailable-periods.info.reason_placeholder')" 
        :labelAsColumn="true"
      />
    </div>
    <button class="btn btn-primary ladda-button unavailable-period" data-style="zoom-in" type="submit">{{buttonLabel}}</button>
  </form>
</template>

<style>
  
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import TextField from '@root/src/client/components/panels/input/TextField.vue';
import DateInput from '@root/src/client/components/panels/input/DateInput.vue';

import { useAvailabilityAdmin } from '../../../composables/useAvailabilityAdmin';

export default defineComponent({
  props: {
      availability: {
        type: Object as PropType<any>,
        required: true
      }
  },
  components: {
    DateInput,
    TextField,
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { unavailablePeriodForm, addUnavailablePeriod  } = useAvailabilityAdmin({}, context);

    const { availability } = toRefs(props);

    // We update the unavailable period form
    unavailablePeriodForm.availabilityID = availability.value._id;

    const buttonLabel = computed(() => {
      return app.$t('shop.availabilities.unavailable-periods.create.button')
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=unavailable-period] button.ladda-button.unavailable-period' );
      laddaSubmit = Ladda.create(button!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      addUnavailablePeriod().then((result:any) => {
        if(result.updated) {
          context.emit('availability-updated', result.availability);
        }
        laddaSubmit!.stop();
      })
    }

    return {
      availability,
      buttonLabel,
      unavailablePeriodForm,
      onFormSubmit
    }
  }
})
</script>