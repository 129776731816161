<template>
  <div class="unavailable-periods">

    <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
  
    <b-table 
        outlined striped
        :items="availability.unavailablePeriods"
        :fields="listFields"
        show-empty
        :empty-text="$t('shop.availabilities.unavailable-periods.list.no-period')"
        ref="listItems">
      <template v-slot:cell(select)="row">
        <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
      </template>
      <template v-slot:cell(reason)="row">
        <span v-if="row.item.type == 'ORDER'">
          {{ $t('shop.availabilities.unavailable-periods.info.order') + ': ' }}
          <a href="javascript:void(0)" @click="showOrderDetails(row.item.orderID)">{{ row.item.orderID }}</a>
        </span>
        <span v-else-if="row.item.type == 'ADMIN' && row.item.reason != ''">
          {{ $t('shop.availabilities.unavailable-periods.info.reason') + ': ' + row.item.reason }}
        </span>
      </template>        
      <template v-slot:cell(options)="row">
        <a v-if="row.item.type == 'ADMIN'" href="javascript:void(0)" @click="confirmRemoveItem(row.index)">{{$t('shop.availabilities.unavailable-periods.list.options.delete')}}</a>
      </template>
    </b-table>

    <button class="btn btn-primary" @click="showCreateItemModal()">{{$t('shop.availabilities.unavailable-periods.list.create-button')}}</button>
    
    <b-modal size="xl" ref="createItemModal" :no-enforce-focus="true" :title="$t('shop.availabilities.unavailable-periods.create.title')" hide-footer lazy>
      <UnavailablePeriodForm :availability="availability" v-on:availability-updated="onItemUpdated" />
    </b-modal>

    <RecapModal ref="orderRecapModal" />

    <b-modal ref="removeItemModal" 
        hide-header
        @ok="removeItem">
      {{$t('shop.availabilities.unavailable-periods.list.delete-confirmation')}}
    </b-modal>

  </div>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';

import { languagesTypes, authenticationTypes } from '@fwk-client/store/types';
import { formatDay, formatTime } from '@igotweb-node-api-utils/formatter';

import UnavailablePeriodForm from './UnavailablePeriodForm.vue';
import RecapModal from '../order/RecapModal.vue';

import { useOrderAdmin } from '../../../composables/useOrderAdmin';
import { useAvailabilityAdmin } from '../../../composables/useAvailabilityAdmin';

export default defineComponent({
  props: {
    availability: {
        type: Object as PropType<any>,
        required: true
      }
  },
  components: {
    UnavailablePeriodForm,
    RecapModal
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { availability } = toRefs(props);

    const { removeUnavailablePeriod }  = useAvailabilityAdmin({}, context);
    const { orders, isListLoading, updateListOrders, getOrderFromID }  = useOrderAdmin({
      storage: 'local',
      filterStatus: ["SCHEDULED"]
    }, context);
    if(orders.value.length === 0 && !isListLoading.value) {
      updateListOrders();
    }

    const indexToRemove:Ref<number|undefined> = ref(undefined);

    const listItemsSelected:Ref<any[]> = ref([]);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const orderRecapModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return availability.value.unavailablePeriods.value.length
    })

    const listFields:Ref<any[]> = ref([
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "startDate",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDay(value, currentLanguageCode.value, {isUTC:true}) + " - " + formatTime(value, currentLanguageCode.value, {isUTC:true});
        }
      },
      {
        key: "endDate",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDay(value, currentLanguageCode.value, {isUTC:true}) + " - " + formatTime(value, currentLanguageCode.value, {isUTC:true});
        }
      },
      {
        key: "reason",
        label: ""
      },
      {
        key: "options"
      }
    ]);

    onMounted(() => {
      updateTableLabels();
    })

    const updateTableLabels = () => {
      listFields.value[1].label = app.$t('shop.availabilities.unavailable-periods.list.headers.startDate') as string;
      listFields.value[2].label = app.$t('shop.availabilities.unavailable-periods.list.headers.endDate') as string;
      listFields.value[3].label = app.$t('shop.availabilities.unavailable-periods.list.headers.reason') as string;
      listFields.value[4].label = app.$t('shop.availabilities.unavailable-periods.list.headers.options') as string;
    }

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

    const onItemUpdated = (updatedAvailability:any) => {
      // @ts-ignore
      createItemModal.value.hide();
      // We emit
      context.emit('availability-updated', updatedAvailability);
    }
    
    const confirmRemoveItem = (indexToRemoveParam:any) => {
      indexToRemove.value = indexToRemoveParam;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const showOrderDetails = (orderID:any) => {
      var order = getOrderFromID(orderID);
      // @ts-ignore
      orderRecapModal.value.show(order);
    }

    const removeItem = () => {
      if(indexToRemove.value === undefined) {
        return;
      }
      removeUnavailablePeriod(availability.value._id, indexToRemove.value).then((result:any) => {
        if(result.updated) {
          // We emit
          context.emit('availability-updated', result.availability);
        }
        // We reset the index to remove
        indexToRemove.value = undefined;
      })
    }

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
       updateTableLabels();
      },
      { deep: false }
    )

    return {
      // listLoading,
      availability,

      listItemsSelected,
      listItems,
      listFields,
      
      showNumberOfRows,
      numberOfRows,

      formatDay,
      
      showCreateItemModal,
      createItemModal,
      onItemUpdated,
      
      
      removeItemModal,
      confirmRemoveItem,
      removeItem,

      showOrderDetails,
      orderRecapModal,
      hasUserRole,
      apiRoles
    }
  }
})
</script>