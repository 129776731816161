<template>
  <div class="ibox products">
    <div class="ibox-title">
      <h2>{{ $t('shop.availabilities.list.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row m-b-md">
        <div class="col-lg-6">
          <Select
            v-if="products.length > 0"
            :labelClass="{'col-auto':true, 'col-form-label':true}"
            :fieldClass="{'col':true}"
            :label="$t('shop.availabilities.list.select-activity.label')"
            :placeholder="$t('shop.availabilities.list.select-activity.placeholder')"
            :selectOptions="{
              options: products,
              getOptionLabel : option => getLocalizedText(option.title),
              reduce : option => option._id
            }"
            v-bind:value.sync="filters.activityID" 
            :labelAsColumn="true"
          />
        </div>
      </div>

      <div class="row">

        <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
      
        <b-table 
            outlined striped
            :items="availabilities"
            :fields="listFields"
            ref="listItems">
          <template v-slot:cell(select)="row">
            <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
          </template>    
          <template v-slot:cell(title)="row">
            {{getLocalizedText(row.item.title)}}
          </template> 
          <template v-slot:cell(slotsPerWeekday)="row">
            <ul v-if="row.item.slotsPerWeekday && Object.keys(row.item.slotsPerWeekday).length > 0">
              <li v-for="(weekday, index) in Object.keys(row.item.slotsPerWeekday)" :key="index + '_slotsPerWeekday_' + row.item._id">
                {{ getLocalizedText(weekDayLabels[weekday]) }}
                <ul>
                  <li v-for="(slot, indexSlot) in row.item.slotsPerWeekday[weekday]" :key="index + '_slot_' + indexSlot">{{ getTimeForSlot(slot.startTime) }} - {{ getTimeForSlot(slot.endTime) }}</li>
                </ul>
              </li>
            </ul>
            <span v-else> - </span>
          </template> 
          <template v-slot:cell(activities)="row">
            <ul v-if="getAssociatedActivities(row.item).length > 0">
              <li v-for="(activity, index) in getAssociatedActivities(row.item)" :key="index + '_activity_' + activity._id">
                {{ getLocalizedText(activity.title) }}
              </li>
            </ul>
            <span v-else> - </span>
          </template> 
          <template v-slot:cell(unavailalePeriods)="row">
            <ul v-if="row.item.unavailablePeriods && row.item.unavailablePeriods.length > 0">
              <li v-for="(unavailablePeriod, index) in row.item.unavailablePeriods" :key="index + '_unavailablePeriod_' + row.item._id">
                {{ formatDay(unavailablePeriod.startDate, currentLanguageCode, {type:"NUMERIC", isUTC:true})+ '-' + formatTime(unavailablePeriod.startDate, currentLanguageCode, {isUTC:true}) + ' - ' + formatDay(unavailablePeriod.endDate, currentLanguageCode, {type:"NUMERIC"})+ '-' + formatTime(unavailablePeriod.endDate, currentLanguageCode, {isUTC:true}) }}
              </li>
            </ul>
            <span v-else>{{ $t('shop.availabilities.unavailable-periods.list.no-period') }}</span>
          </template> 
          <template v-slot:cell(options)="row">
            <span v-if="isAvailabilityUpdateAllowed">
              <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('shop.availabilities.list.options.update')}}</a>
              /
              <a href="javascript:void(0)" @click="showUnavailablePeriodsModal(row.item)">{{$t('shop.availabilities.list.options.unavailablePeriods')}}</a>
              /
            </span>
            <span v-if="isAvailabilityRemoveAllowed">
              <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('shop.availabilities.list.options.delete')}}</a>
            </span>
          </template>
        </b-table>

        <button v-if="isAvailabilityCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('shop.availabilities.list.create-button')}}</button>
        <button v-if="isAvailabilityPublishAllowed" class="btn btn-primary ml-2" @click="showPublishModal()">{{$t('shop.availabilities.list.publish-button')}}</button>
        
        <b-modal size="xl" ref="createItemModal" :no-enforce-focus="true" :title="$t('shop.availabilities.create.title')" hide-footer lazy>
          <AvailabilityForm v-on:availability-created="onItemCreated" />
        </b-modal>

        <b-modal size="xl" ref="updateItemModal" :no-enforce-focus="true" :title="$t('shop.availabilities.update.title', {availabilityID: itemToUpdate._id})" hide-footer lazy>
          <AvailabilityForm :availability="itemToUpdate" v-on:availability-updated="onItemUpdated" />
        </b-modal>

        <b-modal size="xl" ref="unavailablePeriodsModal" :no-enforce-focus="true" :title="$t('shop.availabilities.unavailable-periods.title', {availabilityID: itemToUpdate._id})" hide-footer lazy>
          <ListUnavailablePeriods :availability="itemToUpdate" v-on:availability-updated="onItemUpdated" />
        </b-modal>

        <b-modal size="xl" ref="publishItemModal" :title="$t('shop.availabilities.publish.title')" hide-footer lazy>
          <PublishAvailabilities />
        </b-modal>

        <b-modal ref="removeItemModal" 
            hide-header
            @ok="removeItem">
          {{$t('shop.availabilities.list.delete-confirmation', {apartmentID: itemToRemove._id, apartmentTitle: getLocalizedText(itemToRemove.title)})}}
        </b-modal>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Select from '@fwk-client/components/panels/input/Select.vue';

import AvailabilityForm from './AvailabilityForm.vue';
import PublishAvailabilities from './Publish.vue';
import ListUnavailablePeriods from './ListUnavailablePeriods.vue';


import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';
import { formatDay, formatTime } from '@igotweb-node-api-utils/formatter';

import { useProductAdmin } from '../../../composables/useProductAdmin';
import { useAvailabilityAdmin } from '../../../composables/useAvailabilityAdmin';
import { useCategoryAdmin } from '../../../composables/useCategoryAdmin';
import { useWeekdays } from '@fwk-client/composables/useWeekdays';

export default defineComponent({
  props: {
    
  },
  components: {
    Select,
    AvailabilityForm,
    PublishAvailabilities,
    ListUnavailablePeriods
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { weekDayLabels } = useWeekdays(props, context);

  const { products }  = useProductAdmin({
        mode: "ACTIVITY"
      }, context);
    const { 
      isListLoading,
      availabilities,
      filters,
      removeAvailability,
      isAvailabilityUpdateAllowed,
      isAvailabilityCreateAllowed,
      isAvailabilityRemoveAllowed,
      isAvailabilityPublishAllowed,
      updateListAvailabilities
    } = useAvailabilityAdmin(props, context);

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});
    const itemToPublish:Ref<any> = ref({});

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);
    const unavailablePeriodsModal:Ref<HTMLElement|null> = ref(null);
    const publishItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return availabilities.value.length
    })

    const listFields:Ref<any[]> = ref([
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "title",
        label: "",
      },
      {
        key: "startDate",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDay(value, currentLanguageCode.value);
        }
      },
      {
        key: "endDate",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDay(value, currentLanguageCode.value);
        }
      },
      {
        key: "slotsPerWeekday",
        label: ""
      },
      {
        key: "activities",
        label: ""
      },
      {
        key: "unavailalePeriods",
        label: ""
      },
      {
        key: "options"
      }
    ]);

    onMounted(() => {
      updateTableLabels();
    })

    const updateTableLabels = () => {
      listFields.value[1].label = app.$t('shop.availabilities.list.headers.title') as string;
      listFields.value[2].label = app.$t('shop.availabilities.list.headers.startDate') as string;
      listFields.value[3].label = app.$t('shop.availabilities.list.headers.endDate') as string;
      listFields.value[4].label = app.$t('shop.availabilities.list.headers.slotsPerWeekday') as string;
      listFields.value[5].label = app.$t('shop.availabilities.list.headers.activities') as string;
      listFields.value[6].label = app.$t('shop.availabilities.list.headers.unavailablePeriods') as string;
      listFields.value[7].label = app.$t('shop.availabilities.list.headers.options') as string;
    }

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }
    
    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showUnavailablePeriodsModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      unavailablePeriodsModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const removeItem = () => {
      removeAvailability(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};
      })
    }

    const showPublishModal = () => {
      // @ts-ignore
      publishItemModal.value.show()
    }

    const getTimeForSlot = (slotTime:number) => {
      let hours = Math.trunc(slotTime);
      let hoursStr = hours.toString();
      if(hours < 10) {
        hoursStr = '0' + hours;
      }
      let minutes = (slotTime - hours) * 60;
      let minutesStr = minutes.toString();
      if(minutes < 10) {
        minutesStr = '0' + minutes;
      }
      return hoursStr + ':' + minutesStr;
    }

    const getAssociatedActivities = (availability:any) => {
      let activities:any[] = [];
      for(var product of products.value) {
        if(product.availabilities_ids && product.availabilities_ids.includes(availability._id)) {
          activities.push(product)
        }
      }
      return activities;
    }

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
       updateTableLabels();
      },
      { deep: false }
    )

    watch(
      () => filters.activityID,
      (val:any, oldVal:any) => {
        updateListAvailabilities();
      },
      { deep: false }
    )

    return {
      currentLanguageCode,
      listLoading,
      products,
      availabilities,
      filters,
      weekDayLabels,

      listItemsSelected,
      listItems,
      listFields,
      isAvailabilityUpdateAllowed,
      isAvailabilityCreateAllowed,
      isAvailabilityRemoveAllowed,
      isAvailabilityPublishAllowed,
      showNumberOfRows,
      numberOfRows,

      formatDay,
      formatTime,
      
      showCreateItemModal,
      createItemModal,
      onItemCreated,
      
      
      removeItemModal,
      itemToRemove,
      confirmRemoveItem,
      removeItem,

      showUpdateModal,
      updateItemModal,
      itemToUpdate,
      onItemUpdated,

      unavailablePeriodsModal,
      showUnavailablePeriodsModal,

      publishItemModal,
      showPublishModal,

      getTimeForSlot,
      getAssociatedActivities
    }
  }
})
</script>