import { Ref, watch, computed, ref, reactive, defineProps, PropType, ComputedRef } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useShopAdmin } from "./useShopAdmin";
import { useOrderAdmin } from "./useOrderAdmin";

interface OrderedActivitiesAdminInput {
}

export function useOrderedActivitiesAdmin(props:OrderedActivitiesAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedShop, callShopAdmin } = useShopAdmin(props, {emit})
  const { orders, filters:orderFilters, updateListOrders, isListLoading:isOdersListLoading, getOrderActivities, getOrderFromID } = useOrderAdmin({
    storage: "local",
    filterStatus: ["SCHEDULED"]
  }, {emit});

  const filters:any = reactive({
    includePastActivities: false
  })

  var isListLoading:ComputedRef<boolean> = computed(() => {
    return isOdersListLoading.value
  })

  const localOrderedActivities:ComputedRef<any[]> = computed(() => {
    let activities = [];
    let ordersToCheck = orders.value;
    let includePastActivities = filters.includePastActivities;
    // We loop over the orders to get the ordered activities.
    for(let order of ordersToCheck) {
      let cartActivities = getOrderActivities(order);
      for(let cartActivity of cartActivities) {
        // We filter activities booked in the past if filter is activated
        if(!includePastActivities && new Date(cartActivity.startDate).getTime() < new Date().getTime()) {
          continue;
        }
        activities.push({
          cartActivity: cartActivity,
          contact: order.contact,
          order_id: order._id
        })
      }
    }
    // We order activities by start date
    activities.sort((a, b) => {
      return new Date(a.cartActivity.startDate).getTime() - new Date(b.cartActivity.startDate).getTime();
    })
    return activities;
  });

  const orderedActivities = computed(() => {
      return localOrderedActivities.value;
  })

  const updateListOrderedActivities = async () => {
    return updateListOrders();
  }
  updateListOrderedActivities();



  // We check when there is a new shop selected
  watch(
    () => selectedShop.value.shop._id,
    (val:any, oldVal:any) => {
      // We update the categories when the selected shop is updated
      updateListOrderedActivities();
    },
    { deep: false }
  )

  return {
    orderedActivities,
    isListLoading,
    updateListOrderedActivities,
    getOrderFromID,
    filters
  }
  
}