<template>
  <b-modal size="lg" ref="orderRecapModal" :title="$t('shop.order.recap.title', {orderID: order != null ? order._id : ''})" hide-footer lazy>
    <Recap v-if="order != null" :order="order" v-on:order-updated="onItemUpdated" />
  </b-modal>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef, toRefs, defineExpose } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { languagesTypes } from '@fwk-client/store/types';

import Recap from './options/OrderRecap.vue';

export default defineComponent({
  props: {
  },
  components: {
    Recap,
  },
  emits: ["order-updated"],
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const order:Ref<any> = ref(null);

    const orderRecapModal:Ref<HTMLElement|null> = ref(null);

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemUpdated = (item:any) => {
      order.value = item;
      context.emit("order-updated", item);
    }

    const show = (orderToShow:any) => {
      order.value = orderToShow;
      // @ts-ignore
      orderRecapModal.value.show()
    }

    const hide = () => {
      // @ts-ignore
      orderRecapModal.value.hide()
    }

    context.expose({
      show,
      hide
    })

    return {
      currentLanguageCode,
      onItemUpdated,
      orderRecapModal,
      order
    }
  }
})
</script>