<template>
  <div class="ibox orderedActivities">
    <div class="ibox-title">
      <h2>{{ $t('shop.orders.activities.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      
      <div class="row m-b-md">
        <div class="col-auto buttons">
          <div class="includePastActivities">
            <SwitchCheck 
              v-bind:value.sync="filters.includePastActivities" 
              id="includePastActivities" 
              :label="$t('shop.orders.activities.includePastActivities')" 
              :labelAsColumn="true"
            />
          </div>
          <div class="refreshButton">
            <a class="btn btn-white" @click="onRefreshClick">
              <i class="fa fa-refresh"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="row">

        <b-pagination
            v-model="currentPage"
            :total-rows="orderedActivities.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

      </div>

      <div class="row">
    
        <b-table 
            outlined striped
            :items="orderedActivities"
            :fields="listFields"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
            ref="listItems">      
          <template v-slot:cell(_id)="row">
            {{ row.item.order_id }}
            <div>
              <a href="javascript:void(0)" @click="showOrderRecapModal(row.item)">{{$t('shop.orders.options.recap')}}</a> 
            </div>
          </template>
          <template v-slot:cell(dateTime)="row">
            <div>{{ formatDate(row.item.cartActivity.startDate) }}</div>
            <div>{{ formatDateTime(row.item.cartActivity.startDate) }} - {{ formatDateTime(addMinutesToDate(new Date(row.item.cartActivity.startDate), row.item.cartActivity.activity.duration)) }}</div>
          </template>
        </b-table>

        <b-modal size="lg" ref="orderRecapModal" :title="$t('shop.order.review.title', {orderID: itemToUpdate._id})" hide-footer lazy>
          <OrderRecap :order="itemToUpdate" />
        </b-modal>
        
      </div>
      
    </div>
  </div>
</template>

<style>
  .orderedActivities .col-auto.buttons {
    text-align: right;
    display:flex;
  }

  .orderedActivities .col-auto.buttons > div {
    margin:5px;  /* and that, will result in a 10px gap */
  }

  /* no wrap */
  .orderedActivities .col-auto.buttons .includePastActivities label {
    white-space: nowrap;
  }

  .orderedActivities .isDelivered {
    font-weight: bold;
    color: #FF0000;
  }
</style>



<script lang="ts">
import { Ref, defineComponent, ComputedRef, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import OrderRecap from './order/options/OrderRecap.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

import { languagesTypes } from '@fwk-client/store/types';

import { formatDate, formatDay, formatTime, FormatDateType } from '@igotweb-node-api-utils/formatter';
import { addMinutes } from '@igotweb-node-api-utils/date';

import { useOrderedActivitiesAdmin } from '../../composables/useOrderedActivitiesAdmin';


export default defineComponent({
  props: {
  },
  components: {
    OrderRecap,
    SwitchCheck
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { orderedActivities, updateListOrderedActivities, isListLoading, getOrderFromID, filters } = useOrderedActivitiesAdmin(props, context);

    const itemToUpdate:Ref<any> = ref({});

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value;
    })

    const listItems:Ref<HTMLElement|null> = ref(null);
    const orderRecapModal:Ref<HTMLElement|null> = ref(null);    

    const showNumberOfRows:Ref<boolean> = ref(false);

    const perPage:number = 10;
    const currentPage:Ref<number> = ref(1);

    const numberOfRows:Ref<number> = computed(() => {
        return orderedActivities.value.length
    })              

    const listFields = [
      {
        key: "_id",
        label: ""
      },
      {
        key: "title",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return item.cartActivity.activity.title;
        }
      },
      {
        key: "dateTime",
        label: ""
      },
      {
        key: "cartActivity.numberOfPersons",
        label: ""
      },
      {
        key: "totalAmount",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return app.formatPriceAmount(item.cartActivity.amount);
        }
      },
      {
        key: "contact.email.email",
        label: ""
      },
      {
        key: "contact",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return item.contact.firstname + " " + item.contact.lastname;
        }
      }
    ];

    onMounted(() => {
      listFields[0].label = app.$t('shop.orders.activities.headers.id') as string;
      listFields[1].label = app.$t('shop.orders.activities.headers.title') as string;
      listFields[2].label = app.$t('shop.orders.activities.headers.dateTime') as string;
      listFields[3].label = app.$t('shop.orders.activities.headers.numberOfPersons') as string;
      listFields[4].label = app.$t('shop.orders.activities.headers.amount') as string;
      listFields[5].label = app.$t('shop.orders.activities.headers.email') as string;
      listFields[6].label = app.$t('shop.orders.activities.headers.name') as string;
      enableAutoRefresh();
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const enableAutoRefresh = () => {
      setTimeout(() => {
        // When the component is unloaded, the timeout is still active
        updateListOrderedActivities();
        enableAutoRefresh();
      }, 5000*60);
    }

    const onRefreshClick = () => {
      updateListOrderedActivities();
    }

    const showOrderRecapModal = (item:any) => {
      let order = getOrderFromID(item.order_id);
      itemToUpdate.value = order;
      // @ts-ignore
      orderRecapModal.value.show()
    }

    const formatDate = (date:Date) => {
        return formatDay(date, currentLanguageCode.value, {type: FormatDateType.LONG, isUTC:true});
    }

    const formatDateTime = (date:Date) => {
        return formatTime(date, currentLanguageCode.value, {isUTC:true});
    }

    const addMinutesToDate = (date:Date, minutes:number) => {
        return addMinutes(date, minutes);
    }

    return {
      orderedActivities,

      listLoading,
      showNumberOfRows,
      numberOfRows,
      listItems,
      listFields,
      itemToUpdate,
      
      orderRecapModal,
      
      onRefreshClick,
      filters,

      showOrderRecapModal,

      perPage,
      currentPage,
      formatDate,
      formatDateTime,
      addMinutesToDate

    }
  }
})
</script>