<template>
  <div class="slotsPerWeekday">
    <div class="row">
      <div class="col-lg-12">
        <h3>{{ getLocalizedText(labels.title) }}</h3>
      </div>
    </div>
    <div class="row" v-if="weekDaysWithoutSlot.length > 0">
      <div class="col-lg-12">
        <Select 
          :selectOptions="{
            options: weekDaysWithoutSlot,
            getOptionLabel : weekDay => getLocalizedText(weekDayLabels[weekDay])
          }"
          v-bind:value.sync="weekDayToAdd" 
          :label="getLocalizedText(labels.weekDayToAddLabel)" 
          :placeholder="getLocalizedText(labels.weekDayToAddPlaceholder)"
          labelAsColumn 
        >
          <template v-slot:buttons>
            <button class="btn btn-primary" type="button" @click="addSelectedWeekday()" :disabled="weekDayToAdd == undefined">{{ getLocalizedText(labels.weekDayToAddButton) }}</button>
          </template>
        </Select>
      </div>
    </div>
    <div class="row">
      <label class="col-lg-3 col-form-label">{{ getLocalizedText(labels.slotsPerWeekday) }}</label>
      <div class="col-lg-9 weekdays">
        <div class="row weekday"  v-for="(weekDay, index) in weekDaysWithSlot" :key="index + '_weekday'">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-6">
                <b>{{ getLocalizedText(weekDayLabels[weekDay]) }}</b>
              </div>
              <div class="col-lg-6">
                <button class="btn btn-primary" type="button" @click="removeWeekday(weekDay)">{{ getLocalizedText(labels.removeWeekDayButton) }}</button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div v-if="slotsPerWeekday[weekDay] && slotsPerWeekday[weekDay].length == 0">{{ getLocalizedText(labels.noSlotInDay) }}</div>
                <div v-else>
                  <div class="slot" v-for="(daySlot, indexDaySlot) in slotsPerWeekday[weekDay]" :key="index + '_weekday'+ indexDaySlot + '_daySlot'">
                    {{ getTimeForSlot(daySlot.startTime) }} - {{ getTimeForSlot(daySlot.endTime) }}
                    <a href="javascript:void(0)" @click="removeDaySlot(weekDay, indexDaySlot)">{{ getLocalizedText(labels.removeDaySlot) }}</a>
                  </div>
                </div>
              </div>
              <div class="col-12 add-day-slot">
                <div class="d-flex justify-content-end">
                  <v-select 
                    :options="daySlotTimes" 
                    :getOptionLabel="getTimeForSlot"
                    :placeholder="getLocalizedText(labels.daySlotTimesPlaceholder)" 
                    @keypress.enter.native.prevent="" 
                    v-model="daySlotsToAdd[weekDay].startTime" >
                  </v-select>
                  <v-select 
                    :options="daySlotTimes" 
                    :getOptionLabel="getTimeForSlot"
                    :placeholder="getLocalizedText(labels.daySlotTimesPlaceholder)" 
                    @keypress.enter.native.prevent="" 
                    v-model="daySlotsToAdd[weekDay].endTime" >
                  </v-select>
                  <button class="btn btn-primary" type="button" :disabled="daySlotsToAdd[weekDay].startTime == null || daySlotsToAdd[weekDay].endTime == null || daySlotsToAdd[weekDay].startTime >= daySlotsToAdd[weekDay].endTime" @click="addDaySlot(weekDay)">{{ getLocalizedText(labels.daySlotAddButton) }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <JSONTextArea
      v-bind:value.sync="slotsPerWeekday" 
      :label="getLocalizedText(labels.jsonLabel)" 
      :placeholder="'JSON page conent'" 
      :labelAsColumn="true"
    />
  </div>
</template>

<style>

.weekdays .weekday {
  margin-bottom:10px;
}

.add-day-slot > div {
  border-top:1px solid #CCC;
  margin-top:5px;
  padding-top:5px;
  margin-bottom: 10px;
    border-bottom: 1px solid #999;
    padding-bottom: 5px;
}

.add-day-slot .v-select {
  width: 120px;
  margin-right: 20px;
}
  
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, set } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useWeekdays } from '@fwk-client/composables/useWeekdays';

import JSONTextArea from '@root/src/client/components/panels/input/JSONTextArea.vue';
import LocalizedTextInput from '@root/src/client/components/panels/input/LocalizedText.vue';
import { LocalizedText } from '@igotweb-node-api/api/models/interface/localization';
import DateInput from '@root/src/client/components/panels/input/DateInput.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

interface SlotsPerWeekdayInputLabels {
  title:LocalizedText;
  jsonLabel:LocalizedText;
  weekDayToAddLabel:LocalizedText;
  weekDayToAddPlaceholder:LocalizedText;
  weekDayToAddButton:LocalizedText;
  removeWeekDayButton:LocalizedText;
  daySlotTimesPlaceholder:LocalizedText;
  daySlotAddButton:LocalizedText;
  noSlotInDay:LocalizedText;
  removeDaySlot:LocalizedText;
  slotsPerWeekday:LocalizedText;
}

export default defineComponent({
  props: {
      value: {
        type: Object as PropType<any>,
        required: false
      },
      labels : {
        type: Object as PropType<SlotsPerWeekdayInputLabels>,
        required: false
      }
  },
  components: {
    DateInput,
    SwitchCheck,
    LocalizedTextInput,
    Select,
    JSONTextArea
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { weekDays, weekDayLabels } = useWeekdays(props, context);

    const availableLanguageCodes = ['fr','en'];

    const { value } = toRefs(props);

    const computedLabel:SlotsPerWeekdayInputLabels = {
      title: {
        en: "Schedules by day of the week",
        fr: "Horaires par jours de la semaine",
        pl: "Rozkłady według dni tygodnia"
      },
      jsonLabel: {
        en: "Schedules",
        fr: "Horaires",
        pl: "Harmonogramy"
      },
      weekDayToAddLabel: {
        en: "Add a weekday",
        fr: "Ajouter un jour"
      },
      weekDayToAddPlaceholder: {
        en: "Select a day",
        fr: "Selectionnez un jour"
      },
      weekDayToAddButton: {
        en: "Add",
        fr: "Ajouter"
      },
      removeWeekDayButton: {
        en: "Remove the day",
        fr: "Supprimer le jour"
      },
      daySlotTimesPlaceholder: {
        en: "HH:MM",
        fr: "HH:MM"
      },
      daySlotAddButton: {
        en: "Add",
        fr: "Ajouter"
      },
      noSlotInDay: {
        en: "There is no slot for this day yet.",
        fr: "Il n'y a pas de disponibilité pour le jour."
      },
      removeDaySlot: {
        en: "Remove",
        fr: "Supprimer"
      },
      slotsPerWeekday: {
        en: "Slots per weekday",
        fr: "Horaires par jour",
      },
      ...props.labels
    }

    const weekDayToAdd:Ref<string|undefined> = ref(undefined);
    const slotsPerWeekday:Ref<any> = ref(null);
    const daySlotsToAdd:Ref<any> = ref({
      monday: {
        startTime:null,
        endTime:null
      },
      tuesday: {
        startTime:null,
        endTime:null
      },
      wednesday: {
        startTime:null,
        endTime:null
      },
      thursday: {
        startTime:null,
        endTime:null
      },
      friday: {
        startTime:null,
        endTime:null
      },
      saturday: {
        startTime:null,
        endTime:null
      },
      sunday: {
        startTime:null,
        endTime:null
      }
    })

    const daySlotTimes = [
      0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5,
      12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5
    ]

    const getTimeForSlot = (slotTime:number) => {
      let hours = Math.trunc(slotTime);
      let hoursStr = hours.toString();
      if(hours < 10) {
          hoursStr = '0' + hours;
      }
      let minutes = (slotTime - hours) * 60;
      let minutesStr = minutes.toString();
      if(minutes < 10) {
          minutesStr = '0' + minutes;
      }
      return hoursStr + ':' + minutesStr;
    }

    const updateForm = (value:any) => {
      // We take the first 2 characters of the value for the month
      // We take the last 4 characters of the value for the year
      slotsPerWeekday.value = value ? value : null;
    }
    if(props.value) {
      updateForm(props.value);
    }

    const weekDaysWithSlot = computed(() => {
      return weekDays.filter((weekday:string) => {
        return slotsPerWeekday && slotsPerWeekday.value && slotsPerWeekday.value[weekday]
      })
    })

    const weekDaysWithoutSlot = computed(() => {
      return weekDays.filter((weekday:string) => {
        return !(slotsPerWeekday && slotsPerWeekday.value && slotsPerWeekday.value[weekday])
      })
    })

    const addSelectedWeekday = () => {
      if(!weekDayToAdd.value) {
        return;
      }
      if(!slotsPerWeekday.value) {
        slotsPerWeekday.value = {}
      }
      if(!slotsPerWeekday.value[weekDayToAdd.value]) {
        set(slotsPerWeekday.value,weekDayToAdd.value,[]);
      }
      weekDayToAdd.value = undefined;
    }

    const removeWeekday = (weekday:string) => {
      set(slotsPerWeekday.value,weekday,undefined);
      console.log("removeWeekday");
      console.log(Object.keys(slotsPerWeekday.value))
      if(Object.keys(slotsPerWeekday.value).length == 0) {
        slotsPerWeekday.value = null;
      }
    }

    const addDaySlot = (weekday:string) => {
      let startTime = daySlotsToAdd.value[weekday].startTime;
      let endTime = daySlotsToAdd.value[weekday].endTime;
      slotsPerWeekday.value[weekday].push({
        startTime:startTime,
        endTime:endTime
      })
      slotsPerWeekday.value[weekday].sort((slotA:any, slotB:any) => {
        // We order slots by time
        return slotA.startTime - slotB.startTime
      })
      daySlotsToAdd.value[weekday].startTime = null;
      daySlotsToAdd.value[weekday].endTime = null;
    }

    const removeDaySlot = (weekday:string, indexDaySlot:number) => {
      slotsPerWeekday.value[weekday].splice(indexDaySlot, 1);
    }

    watch(
      () => slotsPerWeekday,
      (val:any, oldVal:any) => {
        context.emit('update:value', slotsPerWeekday.value);
      },
      { deep: true }
    )

    watch(
      value,
      (val:any, oldVal:any) => {
        updateForm(val);
      },
      { deep: true }
    )

    return {
      availableLanguageCodes,
      slotsPerWeekday,
      labels: computedLabel,
      weekDayLabels,
      weekDaysWithSlot,
      weekDaysWithoutSlot,
      weekDayToAdd,
      addSelectedWeekday,
      removeWeekday,
      daySlotsToAdd,
      addDaySlot,
      daySlotTimes,
      getTimeForSlot,
      removeDaySlot
    }
  }
})
</script>